/**
 * Calculate the signed ramainder of a division.
 *
 * @param {number} n
 * @param {number} m
 * @returns {number}
 */
export function modulo(n, m) {
  return ((n % m) + m) % m;
}
