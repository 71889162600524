import {modulo} from '../../utilities/math.js';

export class Combobox extends HTMLElement {
  #input = null;

  #container = null;
  #combobox = null;
  #listbox = null;
  #button = null;

  #options = [];
  #suggestions = [];

  connectedCallback() {
    this.#input = this.querySelector('select, datalist');
    this.#container = this.#createComboboxFrom(this.#input);

    this.#combobox = this.#container.querySelector('[role="combobox"]');
    this.#listbox = this.#container.querySelector('[role="listbox"]');
    this.#button = this.#container.querySelector('[type="button"]');

    this.#options = Array.from(this.#listbox.querySelectorAll('[role="option"]'));
    this.#suggestions = this.#options.slice();

    this.#combobox.addEventListener('input', this.#handleComboboxInput);
    this.#combobox.addEventListener('click', this.#handleComboboxClick);
    this.#combobox.addEventListener('keydown', this.#handleComboboxKeyDown);

    this.#listbox.addEventListener('click', this.#handleListboxClick);
    this.#button.addEventListener('click', this.#handleButtonClick);

    document.addEventListener('click', this.#handleDocumentClick, true);

    this.appendChild(this.#container);
    this.#container.previousElementSibling.hidden = true;

    if (this.selectedIndex !== -1) {
      this.#applyOption(this.selectedIndex);
    }
  }

  disconnectedCallback() {
    document.removeEventLisenter('click', this.#handleDocumentClick, true);

    this.#button.removeEventLisenter('click', this.#handleButtonClick);
    this.#listbox.removeEventLisenter('click', this.#handleListboxClick);

    this.#combobox.removeEventLisenter('input', this.#handleComboboxInput);
    this.#combobox.removeEventLisenter('click', this.#handleComboboxClick);
    this.#combobox.removeEventLisenter('keydown', this.#handleComboboxKeyDown);

    this.#container.previousElementSibling.hidden = false;
    this.removeChild(this.#container);

    this.#suggestions = [];
    this.#options = [];

    this.#button = null;
    this.#listbox = null;
    this.#combobox = null;
    this.#container = null;
  }

  get selectedOption() {
    return this.#suggestions.find((element) => (
      element.hasAttribute('aria-selected')
    ));
  }

  get selectedIndex() {
    return this.#suggestions.findIndex((element) => (
      element.hasAttribute('aria-selected')
    ));
  }

  get filter() {
    return this.getAttribute('filter');
  }

  set filter(value) {
    if (value) {
      this.setAttribute('filter', value);
    } else {
      this.removeAttribute('filter');
    }
  }

  get case() {
    return this.getAttribute('case');
  }

  set case(value) {
    if (value) {
      this.setAttribute('case', value);
    } else {
      this.removeAttribute('case');
    }
  }

  isListboxVisible() {
    return !this.#listbox.hidden;
  }

  showListbox() {
    this.#listbox.hidden = false;
    this.#combobox.setAttribute('aria-expanded', 'true');
    this.#button.setAttribute('aria-expanded', 'true');

    return this;
  }

  hideListbox() {
    this.#listbox.hidden = true;
    this.#combobox.setAttribute('aria-expanded', 'false');
    this.#button.setAttribute('aria-expanded', 'false');

    this.#clearSelectedOption();

    return this;
  }

  toggleListbox() {
    if (this.isListboxVisible()) {
      this.hideListbox();
    } else {
      this.showListbox();
    }
  }

  updateListbox(input = '') {
    const needle = this.#formatOption(input);

    this.clearListbox();

    for (const option of this.#options) {
      if (this.#compareOptions(this.#formatOption(option.textContent), needle)) {
        this.#listbox.appendChild(option);
        this.#suggestions.push(option);
      }
    }

    return this;
  }

  clearListbox() {
    this.#listbox.replaceChildren();
    this.#suggestions.splice(0, this.#suggestions.length);

    return this;
  }

  #isOptionInView(index) {
    const option = this.#getOption(index);

    if (!option) {
      return false;
    }

    const containerRect = this.#listbox.getBoundingClientRect();
    const optionRect = option.getBoundingClientRect();

    return (
      optionRect.top >= containerRect.top &&
      optionRect.bottom <= containerRect.bottom
    );
  }

  #hasOptions() {
    return this.#suggestions.length > 0;
  }

  #getOption(value) {
    if (value instanceof Element) {
      return value;
    }

    if (typeof value === 'number') {
      return this.#suggestions[modulo(value, this.#suggestions.length)];
    }

    return null;
  }

  #selectOption(index) {
    const option = this.#getOption(index);

    if (! option) {
      return null;
    }

    this.#clearSelectedOption();

    option.setAttribute('aria-selected', 'true');
    this.#combobox.setAttribute('aria-activedescendant', option.id);

    if (!this.#isOptionInView(option)) {
      option.scrollIntoView({block: 'nearest'});
    }

    return option;
  }

  #selectPreviousOption() {
    if (this.selectedIndex === 0) {
      return this.#selectLastOption();
    } else {
      return this.#selectOption(this.selectedIndex - 1);
    }
  }

  #selectNextOption() {
    return this.#selectOption(this.selectedIndex + 1);
  }

  #selectFirstOption() {
    return this.#selectOption(0);
  }

  #selectLastOption() {
    return this.#selectOption(this.#suggestions.length - 1);
  }

  #applyOption(index) {
    const option = this.#getOption(index);

    if (! option) {
      return null;
    }

    this.hideListbox();

    this.#combobox.value = option.textContent;
    this.#input.value = option.dataset.comboboxValue;

    return this;
  }

  #applySelectedOption() {
    return this.#applyOption(this.selectedOption);
  }

  #clearSelectedOption() {
    const option = this.selectedOption;

    if (! option) {
      return;
    }

    option.removeAttribute('aria-selected');
    this.#combobox.removeAttribute('aria-activedescendant');
  }

  #formatOption(value) {
    return this.case === 'insensitive' ? value.toLowerCase() : value;
  }

  #compareOptions(a, b) {
    switch (this.filter) {
      case '^=':
        return a.startsWith(b);
      case '$=':
        return a.endsWith(b);
      default:
        return a.includes(b);
    }
  }

  #createComboboxFrom(element) {
    const name = element.name || 'combobox';
    const id = element.id || name;
    const label = element.labels[0]?.textContent?.trim() || name;

    const combobox = document.createElement('input');
    combobox.setAttribute('type', 'text');
    combobox.setAttribute('id', `${id}-combobox`);
    combobox.setAttribute('name', `combobox[${name}]`);
    combobox.setAttribute('role', 'combobox');
    combobox.setAttribute('aria-autocomplete', 'list');
    combobox.setAttribute('aria-expanded', 'false');
    combobox.setAttribute('aria-controls', `${id}-listbox`);
    combobox.setAttribute('autocomplete', 'off');
    combobox.classList.add('combobox__input');

    if (this.hasAttribute('placeholder')) {
      combobox.setAttribute('placeholder', this.getAttribute('placeholder'));
    }

    const arrowDownPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    arrowDownPath.setAttribute('d', 'M 3,8 l 8,8 l 8,-8');
    arrowDownPath.setAttribute('stroke-width', '3');

    const arrowDown = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    arrowDown.setAttribute('viewBox', '0 0 24 24');
    arrowDown.setAttribute('width', '24');
    arrowDown.setAttribute('height', '24');
    arrowDown.setAttribute('focusable', 'false');
    arrowDown.setAttribute('aria-hidden', 'true');
    arrowDown.classList.add('combobox__toggle-icon');
    arrowDown.appendChild(arrowDownPath);

    const button = document.createElement('button');
    button.setAttribute('type', 'button');
    button.setAttribute('tabindex', '-1');
    button.setAttribute('aria-label', label);
    button.setAttribute('aria-expanded', 'false');
    button.setAttribute('aria-controls', `${id}-listbox`);
    button.classList.add('combobox__toggle');
    button.appendChild(arrowDown);

    const group = document.createElement('div');
    group.setAttribute('role', 'group');
    group.classList.add('combobox__controls');
    group.appendChild(combobox);
    group.appendChild(button);

    const listbox = document.createElement('ul');
    listbox.setAttribute('id', `${id}-listbox`);
    listbox.setAttribute('role', 'listbox');
    listbox.setAttribute('aria-label', label);
    listbox.classList.add('combobox__listbox');
    listbox.hidden = true;

    for (let i = 0, l = element.children.length; i < l; i++) {
      const option = element.children[i];

      if (!option.value) {
        continue;
      }

      const item = document.createElement('li');
      const label = option.textContent.trim();

      item.setAttribute('role', 'option');
      item.setAttribute('id', option.id || `${id}-listbox-${i}`);
      item.setAttribute('data-combobox-value', option.value || label);
      item.classList.add('combobox__listbox-item');
      item.textContent = label;

      if (element.selectedIndex === i) {
        item.setAttribute('aria-selected', 'true');
      }

      listbox.appendChild(item);
    }

    const container = document.createElement('div');
    container.classList.add('combobox');
    container.appendChild(group);
    container.appendChild(listbox);

    return container;
  }

  #handleButtonClick = (event) => {
    this.toggleListbox();
    this.#combobox.focus();
  }

  #handleComboboxClick = (event) => {
    this.toggleListbox();
  }

  #handleComboboxInput = (event) => {
    this.updateListbox(this.#combobox.value);
    this.showListbox();
  }

  #handleComboboxKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        if (this.#hasOptions()) {
          event.preventDefault();

          if (event.altKey) {
            this.showListbox();
          } else {
            if (this.isListboxVisible()) {
              this.showListbox();
              this.#selectNextOption();
            } else {
              this.showListbox();
              this.#selectFirstOption();
            }
          }
        }
        break;
      case 'ArrowUp':
        if (this.#hasOptions()) {
          event.preventDefault();

          if (this.isListboxVisible()) {
            this.showListbox();
            this.#selectPreviousOption();
          } else {
            this.showListbox();
            this.#selectLastOption();
          }
        }
        break;
      case 'Escape':
        event.preventDefault();

        if (this.isListboxVisible()) {
          this.hideListbox();
        } else {
          this.#combobox.value = '';
          this.#input.value = '';
        }
        break;
      case 'Enter':
        event.preventDefault();

        if (this.selectedIndex === -1) {
          this.hideListbox();
        } else {
          this.#applySelectedOption();
        }
        break;
      case 'Home':
        event.preventDefault();

        if (this.selectedIndex === -1) {
          this.#combobox.setSelectionRange(0, 0);
        } else {
          this.#selectFirstOption();
        }
        break;
      case 'End':
        event.preventDefault();

        if (this.selectedIndex === -1) {
          this.#combobox.setSelectionRange(this.#combobox.length, this.#combobox.length);
        } else {
          this.#selectLastOption();
        }
        break;
    }
  }

  #handleListboxClick = (event) => {
    const option = event.target.closest('[role="option"]');

    if (! option) {
      return;
    }

    event.preventDefault();
    this.#applyOption(option);
  }

  #handleDocumentClick = (event) => {
    if (! this.contains(event.target)) {
      setTimeout(() => this.hideListbox(), 200);
    }
  }
}
