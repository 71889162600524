import {Accordion} from '@/components/Accordion/Accordion.js';
import {Combobox} from '@/components/Combobox/Combobox.js';
import {Disclosure} from '@/components/Disclosure/Disclosure.js';
import {Gallery} from '@/components/Gallery/Gallery.js';
import {Carousel} from '@/components/Carousel/Carousel.js';
import {CarouselItem} from '@/components/Carousel/CarouselItem.js';

export default {
  install() {
    if (! ('customElements' in window)) {
      return;
    }

    window.customElements.define('custom-accordion', Accordion);
    window.customElements.define('custom-combobox', Combobox);
    window.customElements.define('custom-disclosure', Disclosure);
    window.customElements.define('custom-gallery', Gallery);

    window.customElements.define('custom-carousel', Carousel);
    window.customElements.define('custom-carousel-item', CarouselItem);
  }
};
